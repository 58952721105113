<template>
  <div class="hello">
    <form
      action="/"
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      netlify
    >
      <input type="hidden" name="form-name" value="contact" />
      <div class="field">
        <label
          >Name* <br />
          <input type="text" name="name" id="input-name" @focusout="check('name')" required
        /></label>
        <div class="validation" v-if="validation.name_valid">
          <img src="@/assets/images/illustrations/icon_OK.svg" alt="">
        </div>
         <div class="validation" v-if="validation.name_valid==false">
          <img src="@/assets/images/illustrations/icon_ERROR.svg" alt="">
        </div>
      </div>
      <div class="field">
        <label
          >E-mail* <br />
          <input type="email" name="email" id="input-email" required @focusout="check('email')"
        /></label>
         <div class="validation" v-if="validation.email_valid">
          <img src="@/assets/images/illustrations/icon_OK.svg" alt="">
        </div>
         <div class="validation" v-if="validation.email_valid==false">
          <img src="@/assets/images/illustrations/icon_ERROR.svg" alt="">
        </div>
      </div>
      <div class="field">
        <label
          >Número teléfono* <br />
          <input type="text" name="phone" id="input-phone" required @focusout="check('phone')"
        /></label>
        <div class="validation" v-if="validation.phone_valid">
          <img src="@/assets/images/illustrations/icon_OK.svg" alt="">
        </div>
         <div class="validation" v-if="validation.phone_valid==false">
          <img src="@/assets/images/illustrations/icon_ERROR.svg" alt="">
        </div>
      </div>
      <div class="field">
        <label
          >Mensaje* <br />
          <textarea style="min-height: 120px;" name="message" id="input-message" required @focusout="check('message')"></textarea>
        </label>
        <div class="validation" v-if="validation.message_valid">
          <img src="@/assets/images/illustrations/icon_OK.svg" alt="">
        </div>
         <div class="validation" v-if="validation.message_valid==false">
          <img src="@/assets/images/illustrations/icon_ERROR.svg" alt="">
        </div>
      </div>
      <p style="text-align: center">
        <button type="submit" @click="submit()">Get back to me</button>
      </p>
    </form>
   <div id="submitted" class="submitted">
     <br>
      <h3 style="text-align: center;">Gracias por su mensaje!</h3>
      <h4  style="text-align: center;">We'll be in contact with you shortly </h4>
   </div>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  data(){
    return{
      validation:{
        name_valid: null,
      email_valid: null,
      phone_valid: null,
      message_valid: null
      }
    }
  },
  methods: {
    check: function(field){
      const input = document.getElementById('input-' + field)
      console.log(input, input.validity)
      if(input.validity.valid){
        this.validation[field+'_valid'] = true
      } else {
        this.validation[field+'_valid'] = false
      }
    },
    submit() {
      this.validation = {
        name_valid: null,
        email_valid: null,
        phone_valid: null,
        message_valid: null
      }
      document.getElementById('submitted').classList.toggle('active')
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables";
.hello {
  text-align: left;
  .field{
    display: flex;
    margin-top: 20px;
    label {
    margin-left: 20px;
    width: 90%;
  }
  .validation{
    width: 35px;
    margin-top: 21px;
  }
  input,
  textarea {
    width: 92%;
    outline: none;
    border: none;
    background-color: $gray-medium;
    padding: 10px 20px;
    border-radius: 15px;
    &:focus {
      box-shadow: 1px 1px 10px 1px lightgray;
    }
    @media screen and (max-width: 660px)  {
        width: 80%;
      }
  }
  }
  .submitted{
    display: none;
    .active{
      display: block;
    }
  }
}
</style>

