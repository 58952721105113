<template>
  <div class="wrapper">
      <div class="content">
        <h1>Contacto</h1>
            <div class="illustration">
              <img src="@/assets/images/illustrations/contacto.svg" alt="">
            </div>
            <div>

            </div>
            <div>
              <ContactForm />
            </div>
      </div>
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
export default {
  name: 'Contact',
  components: {
    ContactForm
  },
  mounted () {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .content{
      margin-bottom: 150px !important;
        .illustration{
    width: 45%;
    max-width: 600px;
    margin: auto;
  }
    }
</style>
